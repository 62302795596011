import React, { Component } from 'react';
import AdminHeader from '../AdminHeader';
import CategoryList from './CategoryList';
import axios from 'axios';
import NewCategoryModal from './NewCategoryModal';
import { API_ADMIN } from '../../../hook';

class Category extends Component {
  state = {
    categories: [],
    message: '', // To store success or error messages
  };

  componentDidMount() {
    this.resetState();
  }

  // Fetch categories from the API
  getCategories = () => {
    axios
      .get(`${API_ADMIN}/category/`, {
        method: 'GET',
        mode: 'cors',
        credentials: 'include', // Include if your request involves credentials
      })
      .then((res) => {
        const { message, data } = res.data; // Destructure message and data
        this.setState({ categories: data, message }); // Update categories and message
      })
      .catch((error) => {
        console.error('Error fetching list categories on administrator page:', error);
        this.setState({ message: 'Error fetching categories.' }); // Set error message
      });
  };

  // Add a new category using POST request
  addCategory = (categoryData) => {
    axios
      .post(`${API_ADMIN}/category/`, categoryData)
      .then((res) => {
        const { message, data } = res.data; // Destructure response
        this.setState((prevState) => ({
          categories: [...prevState.categories, data], // Add new category to the state
          message, // Display success message
        }));
      })
      .catch((error) => {
        const errorMessage =
          error.response && error.response.data.message
            ? error.response.data.message
            : 'Error creating category.';
        console.error('Error creating category:', error);
        this.setState({ message: errorMessage }); // Set error message
      });
  };

  // Reset state and fetch categories
  resetState = () => {
    this.getCategories();
  };

  render() {
    const { categories, message } = this.state;

    return (
      <div>
        <AdminHeader />
        <div style={{ textAlign: 'center' }} className="mt-1 mb-1">
          {/* Pass addCategory to NewCategoryModal */}
          <NewCategoryModal create={true} resetState={this.resetState} addCategory={this.addCategory} />
        </div>
        {/* Display message */}
        {/* {message && (
          <div style={{ textAlign: 'center', color: 'green', fontWeight: 'bold', marginBottom: '10px' }}>
            {message}
          </div>
        )} */}
        <div className="m-auto" style={{ textAlign: 'center', maxWidth: '90%' }}>
          <CategoryList categories={categories} resetState={this.resetState} />
        </div>
      </div>
    );
  }
}

export default Category;
