import axios from 'axios';
import React, { Component } from 'react';
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { API_ADMIN } from '../../../hook';

export default class CategoryForm extends Component {
  category_url = `${API_ADMIN}/category/`;
  
  state = {
    pk: "",
    name: "",
    code: "001",
  };

  componentDidMount() {
    // If editing, set initial form values based on passed props
    if (this.props.category) {
      const { pk, name, code } = this.props.category;
      this.setState({ pk, name, code });
    }
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  // Handle form submission for creating a category
  createCategory = e => {
    e.preventDefault();
    axios.post(this.category_url, this.state, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
    })
      .then(() => {
        this.props.resetState();  // Refresh the category list
        this.props.toggle();       // Close the modal/form
        this.setState({ pk: "", name: "", code:"" });  // Reset form fields
      })
      .catch(error => {
        console.error("Error creating category on administrator page:", error);
        // Optionally show an error message to the user
      });
  };

  // Handle form submission for editing a category
  editCategory = e => {
    e.preventDefault();
    axios.put(this.category_url + this.state.pk, this.state)
      .then(() => {
        this.props.resetState();  // Refresh the category list
        this.props.toggle();       // Close the modal/form
      })
      .catch(error => {
        console.error("Error editing category:", error);
        // Optionally show an error message to the user
      });
  };

  // Default value handling for form fields
  defaultIfEmpty = value => {
    return value || '';  // Return empty string if value is falsy
  };

  render() {
    return (
      <Form onSubmit={this.props.category ? this.editCategory : this.createCategory}>
        <FormGroup>
          <Label for="name">Наименование:</Label>
          <Input
            type="text"
            name="name"
            onChange={this.onChange}
            value={this.defaultIfEmpty(this.state.name)}
            required
          />
        </FormGroup>
        <Button>{this.props.category ? "Изменить" : "Создать"}</Button>
      </Form>
    );
  }
}
