import React, { Component } from 'react';
import AdminHeader from '../AdminHeader';
import axios from 'axios';
import { API_ADMIN } from '../../../hook';
import NewProductModal from './NewProductModal';
import ProductList from './ProductList';
import { Alert } from 'reactstrap';

class Product extends Component {
  state = {
    products: [],
    message: '',
    isError: false
  };

  componentDidMount() {
    this.resetState();
  }
   
  getProducts = () => {
    axios.get(`${API_ADMIN}/${this.props.product_pk}/product/`)
      .then(res => {
        this.setState({
          products: res.data.data,
          message: res.data.message,
          isError: false
        });
      })
      .catch(error => {
        this.setState({
          message: 'Error fetching products. Please try again later.',
          isError: true
        });
        console.error("Error fetching products:", error);
      });
  };

  resetState = () => {
    this.setState({ message: '', isError: false });
    this.getProducts();
  };

  render() {
    return (
      <div>
        <AdminHeader />
        <div style={{ textAlign: "center" }} className="mt-1 mb-1">
          <NewProductModal product_pk={this.props.product_pk} create={true} resetState={this.resetState} />
        </div>

        {/* Display message */}
        {this.state.message && (
          <Alert color={this.state.isError ? 'danger' : 'success'}>
            {this.state.message}
          </Alert>
        )}

        <div className="m-auto" style={{ textAlign: "center", maxWidth: "90%" }}>
          <ProductList 
            product_pk={this.props.product_pk} 
            products={this.state.products} 
            resetState={this.resetState} 
          />
        </div>
      </div>
    );
  }
}

export default Product;
